// extracted by mini-css-extract-plugin
export var Article = "style-module--Article--nR0GM";
export var BackArrow = "style-module--BackArrow--5-+LO";
export var Banner = "style-module--Banner--ZE9Hu";
export var BannerCaption = "style-module--BannerCaption---f524";
export var BannerImage = "style-module--BannerImage--uErn-";
export var Body = "style-module--Body--xwCvT";
export var Breadcrumb = "style-module--Breadcrumb--Qdzg0";
export var Category = "style-module--Category--6i6uY";
export var Content = "style-module--Content--NIdzV";
export var Details = "style-module--Details--rDURv";
export var Footer = "style-module--Footer--x-v+X";
export var Header = "style-module--Header--b2GjZ";
export var Keyword = "style-module--Keyword--psb8d";
export var ReadingTime = "style-module--ReadingTime--6q4B8";